<template>
    <div class="clue_box">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 160px"
                        placeholder="账户名称"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getData()"
                        type="primary"
                        style="margin-right: 0"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                    <span>产品名称</span>
                    <div class="radio_group">
                        <em
                            :class="active == -1 ? 'active' : ''"
                            @click="clickitem({}, -1)"
                            >全部
                        </em>
                        <em
                            @click="clickitem(item, index)"
                            :class="active == index ? 'active' : ''"
                            v-for="(item, index) in productList"
                            :key="item.id"
                            >{{ item.name }}
                        </em>
                    </div>

                    <span>投放站点</span>
                    <div class="radio_group">
                        <em
                            :class="activeLaunchType == -1 ? 'active' : ''"
                            @click="clicklaunchType({}, -1)"
                            >全部
                        </em>
                        <em
                            @click="clicklaunchType(item, index)"
                            :class="activeLaunchType == index ? 'active' : ''"
                            v-for="(item, index) in $searchForm.launchType()"
                            :key="item.value"
                            >{{ item.label }}
                        </em>
                    </div>
                </div>
                <p style="width: 200px">
                    <el-button
                        class="right"
                        type="primary"
                        style="margin-left: 29px"
                        size="small"
                        @click="getData()"
                        >查询</el-button
                    >
                    <el-button
                        class="right"
                        @click="searchEmpty()"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 20px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
        </div>
        <div class="table-title">
            <p>
                <span style="flex: 1">用户填报分配设置</span>
            </p>
        </div>
        <div class="table">
            <Table ref="table" @onSet="onSet" @totalNub="totalNub"></Table>
        </div>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 自动分配设置 -->
        <el-dialog
            :visible.sync="dialogDep"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    自动分配设置
                </div>
            </template>
            <div class="dialog_info">
                <el-form
                    label-position="left"
                    style="margin: 0 auto; width: 85%"
                    size="small"
                >
                    <p class="info">
                        <label>账户名称</label>
                        {{ tableRow.account || '- -' }}
                    </p>
                    <p class="info">
                        <label>产品名称</label>
                        {{
                            tableRow.launchProductBO
                                ? tableRow.launchProductBO.name || '- -'
                                : '- -'
                        }}
                    </p>
                    <p class="info">
                        <label>投放站点</label>
                        {{ $tableDataHandle.launchType(tableRow.channelType) }}
                    </p>
                    <p class="info">
                        <label>公司名称</label>
                        {{ tableRow.companyName || '- -' }}
                    </p>
                    <p class="info">
                        <label>当前分配部门</label>
                        {{ tableRow.allotDepartmentName || '- -' }}
                    </p>
                    <el-form-item label="更改分配部门" required>
                        <!-- <el-select
                            v-model="allotDepartmentId"
                            size="small"
                            filterable
                            style="width: 270px"
                            placeholder="请选择或请输入搜索部门"
                        >
                            <el-option
                                v-for="item in departmentList"
                                :key="item.id"
                                :label="item.departmentName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select> -->
                        <el-cascader
                            size="small"
                            ref="cascader"
                            v-model="allotDepartmentId"
                            :options="departmentList"
                            :style="{ width: '270px' }"
                            :show-all-levels="false"
                            filterable
                            :props="{
                                checkStrictly: true,
                                expandTrigger: 'hover',
                                emitPath: false,
                                value: 'id',
                                label: 'departmentName',
                                children: 'children',
                            }"
                        >
                            <template slot-scope="{ node, data }">
                                <div @click="cascaderClick(data)">
                                    <span>{{ data.departmentName }}</span>
                                </div>
                            </template>
                        </el-cascader>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    style="background: #2370eb"
                    type="primary"
                    @click="subSet"
                    round
                    size="mini"
                    >确 认</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Table from '../table/table.vue';
import { launchProductList, launchUpdate } from '@/api/user/launch/manage.js';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            searchVal: {
                followAdminId: '',
                name: '',
                adminId: '',
            },
            form: {},
            btnP: {},
            pagesize: 20,
            total: 0,
            currentPage: 1,
            direction: 'rtl',
            dialogDep: false,
            isPage: false,
            dateChoice: '',
            tableRow: {},
            departmentList: [],
            productList: [],
            active: -1,
            productChoiceId: '',
            activeLaunchType: -1,
            launchTypeChoiceId: '',
            launchBriefProductList: [],
            allotDepartmentId: '',
        };
    },
    components: {
        Table,
    },
    mounted() {
        this.isPage = true;
        this.getBtn();
    },
    methods: {
        totalNub(i) {
            this.total = i;
        },
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = JSON.parse(JSON.stringify(btn));
            this.getData();
            this.launchProductList();
            this.getDepartmentList();
        },
        cascaderClick(nodeData) {
            this.allotDepartmentId = nodeData.id;

            this.$refs.cascader.checkedValue = nodeData.departmentName;
            this.$refs.cascader.computePresentText();
            this.$refs.cascader.toggleDropDownVisible(false);
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                if (res.code == 200) {
                    this.departmentList = this.$Tree.treeDataTranslate(
                        res.data
                    );
                }
            });
        },
        getTableData() {
            this.currentPage = 1;
            this.getData();
        },
        getData() {
            this.$refs.table.getData(
                this.productChoiceId,
                this.launchTypeChoiceId,
                this.searchVal,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        clickitem(item, index) {
            this.active = index;
            if (item.id) {
                this.productChoiceId = item.id;
            } else {
                this.productChoiceId = '';
            }
            this.currentPage = 1;
            this.getData();
        },
        clicklaunchType(item, index) {
            this.activeLaunchType = index;
            if (item.value) {
                this.launchTypeChoiceId = item.value;
            } else {
                this.launchTypeChoiceId = '';
            }
            this.getData();
        },
        // 投放产品列表
        launchProductList() {
            let data = {
                param: {},
            };
            launchProductList(data).then((res) => {
                // // console.log(res);
                if (res.code == 200) {
                    this.productList = res.data.list;
                    if (this.drawerAdd) {
                        this.$refs.addAccount.$data.productList = res.data.list;
                    }
                }
            });
        },
        // 配置弹窗
        onSet(row) {
            this.tableRow = row;
            this.dialogDep = true;
        },
        // 配置提交
        subSet() {
            let data = {
                param: {
                    launchDO: {
                        id: this.tableRow.id,
                        allotDepartmentId: this.allotDepartmentId,
                    },
                },
            };
            launchUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                    this.allotDepartmentId = '';
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 关闭抽屉
        handleClose() {
            this.dialogDep = false;
            this.allotDepartmentId = '';
            this.getData();
        },
        searchEmpty() {
            this.searchVal = {
                followAdminId: '',
                name: '',
            };
            this.productChoiceId = '';
            this.active = -1;
            this.launchTypeChoiceId = '';
            this.activeLaunchType = -1;
            this.getData();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.clue_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            margin: 0 16px;
        }
        .search-one {
            margin: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                    margin-right: 16px;
                }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }
    .table-title {
        background: #fff;
        padding: 16px;
        p {
            font-size: 14px;
            font-weight: 400;
            display: flex;
            align-items: center;
            color: #333333;
            line-height: 32px;
            button {
                background: #fff;
                border-radius: 2px;
                padding: 0 7px 0 12px;
                height: 26px;
                line-height: 26px;
                color: #333;
                font-size: 12px;
                border-color: #d9d9d9;
                float: right;
                i {
                    font-size: 10px;
                    margin-right: 5px;
                }
            }
            .subBtn {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                color: #fff;
                height: 32px;
                padding: 0 10px;
            }
        }
    }
    .table {
        flex: 1;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .clue_btn {
        margin-top: 10px;
        position: absolute;
        top: -50px;
        right: 0;
        span {
            display: inline-block;
            padding: 10px 20px;
            margin-left: 15px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            background-color: #fff;
        }
    }
}
.clue_assist_body {
    padding: 42px 74px 100px;
    .user {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        .left-spot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
    .user:last-child {
        .left-spot {
            display: inline-block;
            width: 7px;
            height: 7px;
            background: #fff;
            border: 1px solid #2370eb;
            border-radius: 50%;
            margin-right: 20px;
            margin-left: -22px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}

.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
    text-align: center;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 100px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
/deep/ .el-textarea__inner {
    resize: none;
}
.search-two {
    margin-bottom: 10px;
}
/deep/ .el-radio-button__inner {
    width: 100%;
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
.radio_group {
    display: inline-block;
    padding: 6px 0;
    em {
        padding: 7px 15px;
        font-size: 12px;
        font-style: normal;
        white-space: nowrap;
        background: #fff;
        border: 1px solid #dcdfe6;
        font-weight: 500;
        cursor: pointer;
        margin-left: -1px;
    }
    em:first-child {
        border-radius: 4px 0 0 4px;
        margin-left: 0;
    }
    em:last-child {
        border-radius: 0px 4px 4px 0px;
    }
    .active {
        background: #fff !important;
        color: #2162cb !important;
        border-color: #2162cb !important;
    }
    .active + em {
        border-left: 1px solid #2162cb;
    }
}
</style>
