<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="8"> </el-table-column>
            <el-table-column
                prop="account"
                label="账户名称"
                show-overflow-tooltip
                width="130"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.account || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="产品名称" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.launchProductBO.name || '- -' }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="投放站点" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ $tableDataHandle.launchType(scope.row.channelType) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户名称" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.companyName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="分配部门" width="80 " show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.allotDepartmentName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="remark"
                label="操作"
                fixed="right"
                width="80"
            >
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        @click="onSet(scope.row)"
                    >
                        配置
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { launchList } from '@/api/user/launch/manage.js';
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            choiceArr: [],
            isChiose: false,
        };
    },
    methods: {
        // 获取列表
        getData(productId, channelType, searchVal, pageNum, pageSize, btnP) {
            // // console.log(userType, searchVal, pageNum, pageSize);
            this.btnP = btnP;
            let data = {
                param: {},
                pageNum: pageNum,
                pageSize: pageSize,
            };
            if (productId) {
                data.param.productId = productId;
            }
            if (channelType) {
                data.param.channelType = channelType;
            }
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            launchList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNub', res.data.total);
                }
            });
        },
        onSet(row) {
            this.$emit('onSet', row);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-dianhua {
        color: #2370eb;
    }
    .icon-weixin {
        color: #50b674;
    }
    .icon-QQ {
        color: #2370eb;
    }
    .icon-youxiang {
        color: #feb516;
    }
    .icon-qita {
        color: #13ab16;
    }
    .icon-yuyuebaifang {
        color: #f15641;
    }
    .icon-qita1 {
        color: #af90ff;
    }
    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: #fff;
            border: 1px solid #c9c9c9;
        }
        button:nth-child(1) {
            color: #2370eb;
            border-bottom: none;
        }
        button:nth-child(2) {
            color: #c9c9c9;
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
}
.copy {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;
    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    position: relative;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
</style>
<style lang="scss"></style>
